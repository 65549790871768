import { useSelector } from 'react-redux'
import { Link, NavLink } from 'react-router-dom'

import { GrDown } from 'react-icons/gr'
import './nav.scss'
import { useState } from 'react'

const Nav = ({ burger, setBurger }) => {
    const [razdelActive, setRazdelActive] = useState(false)
    const [pagesActive, setPagesActive] = useState(false)
    const [subCategory, setSubCategory] = useState(false)
    const { general } = useSelector(state => state.lang)

    return general && (
        <div className="navBackground">
            <nav className={burger ? 'nav active container' : 'nav container'}>
                <div>
                    <NavLink className={({ isActive }) => isActive ? "active" : ""} to='/' onClick={() => setBurger(false)}>
                        {general.words[0].main}
                    </NavLink>
                </div>
                <div>
                    <div className={pagesActive ? 'nav__razdel show' : 'nav__razdel'} onMouseLeave={() => setPagesActive(false)}>
                        <button type='button' onClick={() => setPagesActive(!pagesActive)} className='nav__razdel-first'>
                            <div>
                                {general.pages[0].pages_tema}
                            </div>
                            <GrDown />
                        </button>
                        <div className="nav__razdel-more">
                            {general.pages.map((item, i) => {
                                return (
                                    <Link onClick={() => {
                                        setPagesActive(false)
                                        setBurger(false)
                                    }} key={i} to={'/page/' + item.n_url}>
                                        {item.title}
                                    </Link>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div>
                    <NavLink className={({ isActive }) => isActive ? "active" : ""} to='/documents' onClick={() => setBurger(false)}>
                        {general.words[0].documents}
                    </NavLink>
                </div>
                <div>
                    <NavLink className={({ isActive }) => isActive ? "active" : ""} to={`/razdel/${general.words[0].deputies_url}`} onClick={() => setBurger(false)}>
                        {general.words[0].deputies}
                    </NavLink>
                </div>
                <div>
                    <NavLink className={({ isActive }) => isActive ? "active" : ""} to='/personal' onClick={() => setBurger(false)}>
                        {general.words[0].employees}
                    </NavLink>
                </div>
                <div>
                    <NavLink className={({ isActive }) => isActive ? "active" : ""} to='/news' onClick={() => setBurger(false)}>
                        {general.words[0].news}
                    </NavLink>
                </div>
                <div>
                    <div className={razdelActive ? 'nav__razdel show' : 'nav__razdel'} onMouseLeave={() => {
                        setRazdelActive(false)
                        setSubCategory(false)
                    }
                    }>
                        <button type='button' onClick={() => setRazdelActive(!razdelActive)} className='nav__razdel-first'>
                            {general.words[0].razdely}
                            <GrDown />
                        </button>
                        <div className="nav__razdel-more">
                            {general.razdel.map((item, i) => {
                                return (
                                    <>
                                        {item.sub_category ?
                                            <div key={i} className="nav__razdel-subCategory" onClick={() => setSubCategory(true)}>
                                                <div className="nav__razdel-subCategory-first">
                                                    {item.title} <GrDown />
                                                </div>
                                                {subCategory &&
                                                    <div className={subCategory ? "nav__razdel-subCategory-more show" : "nav__razdel-subCategory-more"}>
                                                        {
                                                            item.sub_category.map((sub, i) => {
                                                                return (
                                                                    <Link onClick={() => {
                                                                        setRazdelActive(false)
                                                                        setBurger(false)
                                                                        setSubCategory(false)
                                                                    }} key={i} to={'/razdel/' + item.r_url + '&sub_category=' + sub.sub_url}>
                                                                        {sub.sub_title}
                                                                    </Link>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                }
                                            </div>
                                            :
                                            <Link onClick={() => {
                                                setRazdelActive(false)
                                                setBurger(false)
                                            }} key={i} to={'/razdel/' + item.r_url}>
                                                {item.title}
                                            </Link>}
                                    </>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div>
                    <NavLink to='/gallery' className={({ isActive }) => isActive ? "active" : ""} onClick={() => setBurger(false)}>
                        {general.words[0].photo_gallery}
                    </NavLink>
                </div>
            </nav>
        </div>
    )
}

export default Nav