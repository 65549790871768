import { useDispatch, useSelector } from 'react-redux'
import './gallery.scss'
import { useEffect, useState } from 'react'
import { changeGalleryPage, fetchGallery } from 'store/slices/gallerySlice'
import Layout from 'components/layout'
import Grid from 'components/grid'
import Error from 'components/error'
import Loader from 'components/loader'
import FullScreen from 'components/fullScreen'
import NotFound from 'components/notFound'

const Gallery = () => {
    const [slider, setSlider] = useState(false)
    const [activeSlide, setActiveSlide] = useState(0)
    const { lang } = useSelector(state => state.lang)
    const { gallery, page, words, pagination, galleryLoadingStatus } = useSelector(state => state.gallery)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchGallery({ lang, page }))
    }, [lang, page])

    const onChange = (i) => dispatch(changeGalleryPage(i))

    if (galleryLoadingStatus === 'ERR_NETWORK') return <Error />
    if (galleryLoadingStatus === 'loading') return <Loader />

    const dataGallery = gallery.map(item => ({ image: item.big, title: item.title }))

    return gallery.length > 0 ? (
        <Layout title={words} href={words} pagination={pagination} page={page} onChange={onChange}>
            <div className='gallery'>
                <Grid>
                    {gallery.map((item, i) => {
                        return (
                            <img onClick={() => {
                                setActiveSlide(i)
                                setSlider(true)
                            }} className='gallery__img' key={i} src={item.big} alt={item.title} />
                        )
                    })}
                </Grid>
                {slider > 0 && <FullScreen setSlider={setSlider} activeSlide={activeSlide} data={dataGallery} />}
            </div>
        </Layout>
    ) : <NotFound>{lang === 'ky' ? 'Маалымат азырынча жок' : 'Пока данных нет'}</NotFound>
}

export default Gallery