import { useSelector } from 'react-redux'
import './projects.scss'
import { Link } from 'react-router-dom'
import Grid from 'components/grid'

const Projects = () => {
    const { main } = useSelector(state => state.lang)

    return main.vacancy[0] && (
        <div className='projects'>
            <h2 className="projects__title">
                {main.project[0].r_title}
            </h2>
            <div className="projects__items">
                <Grid>
                    {main.project.map((item, i) => {
                        if (i === 0) return null
                        return (
                            <Link to={item.n_url} key={i} className="projects__item">

                                <div className="projects__item-date">
                                    {item.day}.
                                    {item.month}.
                                    {item.year}
                                </div>
                                <h3 className="projects__item-title">
                                    {item.tema}
                                </h3>
                            </Link>
                        )
                    })}
                </Grid>
            </div>
        </div>
    )
}

export default Projects