import Pathname from 'components/pathname'
import './layout.scss'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'

const Layout = ({ children, title, onChange, pagination, page, href, more }) => {
    const { general, lang } = useSelector(state => state.lang)

    return general && (
        <>
            <Helmet>
                <meta name='description' content='Page with list of our comics' />
                <title>{title}</title>
            </Helmet>

            <main className='layout'>
                <div className="container">

                    {title && <Pathname title={title} href={href} />}

                    {children}

                    {pagination &&
                        pagination.length > 1
                        &&
                        <div className="layout__pagination">
                            <button onClick={() => onChange(1)} className={page === 1 ? "layout__pagination-first disable" : "layout__pagination-first"}>
                                {lang === 'ru' ? 'Первый' : 'Биринчи'}
                            </button>
                            <button onClick={() => onChange(page - 1)} className={page - 1 < 1 ? "layout__pagination-prev disable" : "layout__pagination-prev"}>
                                {lang === 'ru' ? 'Пред.' : 'Мурунку'}
                            </button>
                            {pagination.map((item, i) => {
                                return (
                                    <div key={i} onClick={() => onChange(item.pagi)} className={item.pagi === page ? "layout__pagination-item active" : "layout__pagination-item"}>
                                        {item.pagi}
                                    </div>
                                )
                            })}
                            <button onClick={() => onChange(page + 1)} className={page >= pagination.length ? "layout__pagination-next disable" : "layout__pagination-next"}>
                                {lang === 'ru' ? 'След.' : 'Кийинки'}
                            </button>

                            <button onClick={() => onChange(pagination.length)} className={page === pagination.length ? "layout__pagination-last disable" : "layout__pagination-last"}>
                                {lang === 'ru' ? 'Последний' : 'Акыркы'}
                            </button>
                        </div>
                    }
                </div>
            </main>
        </>
    )
}



export default Layout