import './topHeader.scss'
import logo from 'assets/img/LOGOnew.png'
import { FaFacebook, FaGlasses, FaInstagram } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { GiHamburgerMenu } from 'react-icons/gi'
import { RxCross2 } from 'react-icons/rx'
import { changeLang } from 'store/slices/langSlice'
import Search from 'components/search'
import { Helmet } from 'react-helmet'
import { useMemo } from 'react'

const TopHeader = ({ burger, setBurger }) => {
    const { general, lang } = useSelector(state => state.lang)
    const dispatch = useDispatch()

    const handleClick = () => {
        const class2Element = document.querySelector('.bvi-open');
        class2Element.dispatchEvent(new MouseEvent('click', { bubbles: true }));
    }

    const memoizedLogo = useMemo(() => (
        <img src={logo} alt="" />
    ), []);

    return general && (<>
        <Helmet>
            <meta name='description' content='Page with list of our comics' />
            <title>{general.basic_data[0].title_site}</title>
        </Helmet>
        <div className={burger ? 'topHeader container active' : 'topHeader container'}>
            <div className="topHeader__left">
                <div className="topHeader__left-logo">
                    {memoizedLogo}
                    <div className="topHeader__left-title">
                        {general.basic_data[0].title_site}
                    </div>
                </div>
                <div className="topHeader__left-burger" onClick={() => setBurger(!burger)}>
                    <GiHamburgerMenu className='menu' />
                    <RxCross2 className='cross' />
                </div>
            </div>

            <div className="topHeader__right">
                <div className="topHeader__right-lang">
                    <h3 className={lang === 'ru' ? 'active' : ''} onClick={() => dispatch(changeLang('ru'))}>
                        РУС
                    </h3>
                    <h3 className={lang === 'ky' ? 'active' : ''} onClick={() => dispatch(changeLang('ky'))}>
                        КЫР
                    </h3>
                </div>
                <Search />
                <div className="topHeader__right-icons">

                    <a href="#">
                        <FaInstagram />
                    </a>
                    <a href="#">
                        <FaFacebook />
                    </a>
                    <button onClick={handleClick} className='topHeader__right-glasses' type='button'>
                        <FaGlasses />
                    </button>


                    <h3 className='topHeader__right-date'>
                        {general.date[0].day} {general.date[0].month} {general.date[0].year}
                    </h3>
                </div>
            </div>
        </div>
    </>
    )
}


export default TopHeader