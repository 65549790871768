import NewsItem from 'components/newsItem'
import './newsDoc.scss'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import img from 'assets/img/banner3.jpg'

const NewsDoc = () => {
    const { main, general } = useSelector(state => state.lang)

    return (main && general) && (
        <section className={main.vacancy.length < 1 ? 'newsDoc full' : 'newsDoc'}>
            <div className="newsDoc__news">
                <div className="newsDoc__news__title">
                    <h2>
                        {main.words[0].latest_news}
                    </h2>
                    <h3>
                        <Link to='/news'>
                            {main.words[0].all_news}
                        </Link>
                    </h3>
                </div>
                <div className="newsDoc__news__items">
                    {main.news.map((item, i) => (
                        <NewsItem key={i} item={item} />
                    ))}
                </div>
            </div>
            <div className="newsDoc__docs">
                {main.deputy &&
                    <div className="newsDoc__docs__toraga">
                        <h4>
                            {main.words[0].toraga}
                        </h4>
                        <div className="newsDoc__docs__toraga-img">
                            <img src={main.deputy[0].image} alt="" />
                        </div>
                        <h3 className="newsDoc__docs__toraga-name">
                            {main.deputy[0].title}
                        </h3>
                    </div>
                }

                <div className="newsDoc__docs__item recep">
                    <h3 className="newsDoc__docs__item-title">
                        {general.words[0].internet_reception}
                    </h3>
                    <Link to='/reception' className="newsDoc__docs__item-link">
                        {main.words[0].go}
                    </Link>
                </div>
                <div className="newsDoc__docs__item faq">
                    <h3 className="newsDoc__docs__item-title">
                        {general.words[3].faq}
                    </h3>

                    <Link to='/faq' className="newsDoc__docs__item-link">
                        {main.words[0].go}
                    </Link>
                </div>
            </div>
        </section>
    )
}

export default NewsDoc