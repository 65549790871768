import Banner2 from "components/banner2"
import Error from "components/error"
import Loader from "components/loader"
import NewsDoc from "components/newsDoc"
import Urls from "components/urls"
import Vacancies from "components/vacancies"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchMain } from "store/slices/langSlice"
import Docs from "components/docs"
import Projects from "components/projects"

const Main = () => {
    const { lang, main, langLoadingStatus } = useSelector(state => state.lang)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchMain(lang))
    }, [lang])

    if (langLoadingStatus === 'ERR_NETWORK') return <Error />

    return (
        <>
            {langLoadingStatus === 'loading' ? <Loader />
                :
                main &&
                <>
                    <Banner2 />
                    <main>
                        <div className="container">
                            <NewsDoc />
                            <Vacancies />
                            <Projects />
                            <Docs />
                            <Urls />
                        </div>
                    </main>
                </>
            }
        </>
    )
}

export default Main