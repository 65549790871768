import { useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import './swiperGallery.scss'

const SwiperGallery = ({ data, activeSlide = 0 }) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    return data && (
        <div className='swiperGallery'>
            <Swiper
                style={{
                    "--swiper-navigation-color": "#fff",
                    "--swiper-pagination-color": "#fff",
                }}
                spaceBetween={10}
                navigation={true}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper2"
                initialSlide={activeSlide}
            >
                {data.map((item, i) => {
                    return (
                        <SwiperSlide key={i}>
                            <img src={item.image} alt="" />
                        </SwiperSlide>
                    )
                })}

            </Swiper>
            <Swiper
                onSwiper={setThumbsSwiper}
                spaceBetween={10}
                slidesPerView={4}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper"
            >
                {data.length > 1 &&
                    data.map((item, i) => {
                        return (
                            <SwiperSlide key={i}>
                                <img src={item.image} alt="" />
                            </SwiperSlide>
                        )
                    })}
            </Swiper>
        </div>
    )
}

export default SwiperGallery