import './documentsMore.scss'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { HiOutlineClipboardCopy } from 'react-icons/hi'
import { AiFillEye } from 'react-icons/ai'

import { fetchDocumentsMore } from 'store/slices/documentsSlice'
import Layout from 'components/layout'
import Error from 'components/error'
import Loader from 'components/loader'

const DocumentsMore = () => {
    const { lang, general } = useSelector(state => state.lang)
    const { documentsMore, documentsLoadingStatus, wordsMore } = useSelector(state => state.documents)
    const { id } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const docs = 'https://docs.google.com/viewerng/viewer?url='
    const url = window.location.origin + '/documents/' + id

    useEffect(() => {
        dispatch(fetchDocumentsMore({ lang, id }))

    }, [lang, id])

    if (documentsLoadingStatus === 'loading') return <Loader />
    if (documentsLoadingStatus === 'ERR_NETWORK') return <Error />



    if (documentsMore) {
        if (!documentsMore.title) {
            navigate('/documents')
            return null
        }
    }



    return (documentsMore && general) && (
        <Layout title={documentsMore.title} href={<Link to='/documents'>{general.words[0].documents}</Link>}>

            <div className='documentsMore'>
                <div className='documentsMore__top'>
                    <div className="documentsMore__date">
                        <div>
                            {wordsMore.publication_date}: {documentsMore.day}.{documentsMore.month}.{documentsMore.year}
                        </div>
                        <div>
                            {wordsMore.acceptance_date} : {documentsMore.acc_date_day}.{documentsMore.acc_date_month}.{documentsMore.acc_date_year}
                        </div>
                        <div>
                            <div>
                                {wordsMore.place_of_ac}: {documentsMore.place}
                            </div>
                        </div>
                    </div>
                    <div className='documentsMore__views'>
                        <CopyButton url={url} title={wordsMore.copy_link_to_page} />
                        <CopyButton url={docs + documentsMore.file} title={wordsMore.copy_document_link} />
                        <CopyButton url={documentsMore.file} title={wordsMore.copy_download_link} />
                        <AiFillEye /> {documentsMore.views}
                    </div>
                </div>
                <iframe src={`https://docs.google.com/viewer?url=${documentsMore.file}&embedded=true`} title='document_more' width="100%" ></iframe>

                <div className="documentsMore__bottom">
                    <a className='documentsMore__download' href={documentsMore.file}>{wordsMore.download}</a>
                </div>
            </div>
        </Layout>
    )
}

const CopyButton = ({ url, title }) => {
    const [copied, setCopied] = useState(false)
    const { general } = useSelector(state => state.lang)

    const onCopy = (link) => {
        const tempInput = document.createElement('input');
        tempInput.value = link;
        document.body.appendChild(tempInput);
        tempInput.select();
        navigator.clipboard.writeText(link);
        document.body.removeChild(tempInput);
        setCopied(true)

        setTimeout(() => {
            setCopied(false);
        }, 1000);
    }

    return (
        <button onClick={() => onCopy(url)} title={title} type="button" className='documentsMore__btn'>
            {copied && <div className="documentsMore__copy">{general.words[0].copied}</div>}
            <HiOutlineClipboardCopy />
        </button>
    )
}

export default DocumentsMore