import { useEffect } from 'react'
import './news.scss'
import { changeNewsPage, fetchNews } from 'store/slices/newsSlice'
import { useDispatch, useSelector } from 'react-redux'
import NewsItem from 'components/newsItem'
import Layout from 'components/layout'
import Loader from 'components/loader'
import Error from 'components/error'

const News = () => {
    const { lang, general } = useSelector(state => state.lang)
    const { news, pagination, page, newsLoadingStatus } = useSelector(state => state.news)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchNews({ lang, page }))
    }, [lang, page])

    const onChange = (i) => dispatch(changeNewsPage(i))

    if (newsLoadingStatus === 'ERR_NETWORK') return <Error />
    
    return (news && general) && (
        <Layout title={general.words[0].news} href={general.words[0].news} onChange={onChange} pagination={pagination} page={page}>
            {newsLoadingStatus === 'loading' ?
                <Loader />
                :
                <div className='news'>
                    <div className="news__items">
                        {news.map((item, i) => {
                            return (
                                <NewsItem key={i} item={item} />
                            )
                        })
                        }
                    </div>
                </div>
            }
        </Layout>
    )
}

export default News