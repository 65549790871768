import { useSelector } from 'react-redux'
import './banner2.scss'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

const Banner2 = () => {
    const { main } = useSelector(state => state.lang)
    return main && (
        <>
            {main.deputy &&
                <div className='banner2'>
                    <div className="container">
                        <Swiper
                            modules={[Navigation, Pagination, Scrollbar, Autoplay]}
                            spaceBetween={10}
                            slidesPerView={3}
                            autoplay={{
                                delay: 3000,
                                disableOnInteraction: true
                            }}
                            breakpoints={{
                                550: { slidesPerView: 4 },
                                768: { slidesPerView: 5 },
                                1150: { slidesPerView: 6 }
                            }}
                        >
                            {main.deputy.map((item, i) => {
                                return (
                                    <SwiperSlide key={i}>
                                        <div className='banner2__item'>
                                            <img src={item.image} alt="" />
                                            <h4>
                                                {item.title}
                                            </h4>
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>
                </div>}
        </>
    )
}

export default Banner2