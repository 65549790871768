import { AiFillEye } from 'react-icons/ai'
import './newsItem.scss'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

const NewsItem = ({ item }) => {
    const { general } = useSelector(state => state.lang)

    return (
        <div className="newsItem">
            <div className="newsItem__img">
                <img src={item.image} alt="" />
            </div>
            <div className="newsItem__content">
                <div>
                    <h3 className="newsItem__title">
                        {item.tema}
                    </h3>
                    <div className="newsItem__text" dangerouslySetInnerHTML={{ __html: item.small_text }}></div>
                </div>
                <div className='newsItem__bottom'>
                    <h3 className='newsItem__more'>
                        <Link to={`/news/${item.n_url}`}>
                            {general.words[0].more_details}
                        </Link>
                    </h3>
                    <div className='newsItem__right'>
                        <h3 className='newsItem__date'>
                            {item.day}.
                            {item.month}.
                            {item.year}
                        </h3>
                        <h3 className="newsItem__views">
                            <AiFillEye />
                            {item.views}
                        </h3>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewsItem