import { useSelector } from 'react-redux'
import './docs.scss'
import { BsBriefcaseFill } from 'react-icons/bs'
import Grid from 'components/grid'
import { Link } from 'react-router-dom'

const Docs = () => {
    const { main } = useSelector(state => state.lang)

    return main.doc && (
        <section className='docs'>
            <h2 className="docs__title">
                {main.words[0].latest_documents}
            </h2>

            <Grid>
                {main.doc.map((item, i) => {
                    return (
                        <Link to={`/documents/${item.id}`} key={i} className="docs__item">
                            <h3>
                                {item.title}
                            </h3>
                            <div className="docs__item-icon">
                                <BsBriefcaseFill />
                            </div>
                        </Link>
                    )
                })}
            </Grid>
        </section>
    )
}

export default Docs