import { useSelector } from 'react-redux'
import './notFound.scss'

const NotFound = ({ children }) => {
    const { lang } = useSelector(state => state.lang)


    return (
        <h1 className='notFound container'>
            {children ? children :
                lang === 'ky' ? 'Баракча табылган жок' : 'Страница не найдена'}
        </h1>
    )
}

export default NotFound