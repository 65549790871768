import './header.scss'
import { useEffect, useState } from 'react'
import Nav from 'components/nav'
import { fetchGeneral } from 'store/slices/langSlice'
import { useDispatch, useSelector } from 'react-redux'
import TopHeader from 'components/topHeader'

const Header = () => {
    const [burger, setBurger] = useState(false)
    const dispatch = useDispatch()
    const { lang, general } = useSelector(state => state.lang)

    useEffect(() => {
        dispatch(fetchGeneral(lang))
    }, [lang])

    return general && (
        <header>
            <TopHeader burger={burger} setBurger={setBurger} />
            <Nav burger={burger} setBurger={setBurger} />
        </header>
    )
}


export default Header