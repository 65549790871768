import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "api";

const initialState = {
    langLoadingStatus: 'idle',
    lang: localStorage.getItem('lang') || 'ky',
    general: null,
    main: null,
}

export const fetchGeneral = createAsyncThunk(
    'lang/fetchGeneral',
    async (lang) => {
        const res = await axios.get(`${API_URL}/general/?lang=${lang}`)
        return res.data[0]
    }
)

export const fetchMain = createAsyncThunk(
    'lang/fetchMain',
    async (lang) => {
        const res = await axios.get(`${API_URL}/main/?lang=${lang}&news=4&doc=4&vacancy=10&project=3`)
        return res.data[0]
    }
)

const langSlice = createSlice({
    name: 'lang',
    initialState,
    reducers: {
        changeLang(state, action) {
            localStorage.setItem('lang', action.payload);
            state.lang = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchGeneral.pending, state => { state.langLoadingStatus = 'loading' })
            .addCase(fetchGeneral.fulfilled, (state, action) => {
                state.langLoadingStatus = 'finish'
                state.general = action.payload
            })
            .addCase(fetchGeneral.rejected, (state, action) => {
                state.langLoadingStatus = action.error.code
            })

            .addCase(fetchMain.pending, state => { state.langLoadingStatus = 'loading' })
            .addCase(fetchMain.fulfilled, (state, action) => {
                state.langLoadingStatus = 'finish'
                state.main = action.payload
            })
            .addCase(fetchMain.rejected, (state, action) => {
                state.langLoadingStatus = action.error.code
            })
            .addDefaultCase(() => { })
    }
})

const { actions, reducer } = langSlice

export const {
    changeLang
} = actions

export default reducer