import { useDispatch, useSelector } from 'react-redux'
import './documents.scss'
import { useEffect } from 'react'
import { changeDocumentsPage, fetchDocuments } from 'store/slices/documentsSlice'
import Layout from 'components/layout'
import Loader from 'components/loader'
import Error from 'components/error'
import NotFound from 'components/notFound'
import { Link } from 'react-router-dom'

const Documents = () => {



    const { lang, general } = useSelector(state => state.lang)
    const { documents, words, documentsLoadingStatus, pagination, page } = useSelector(state => state.documents)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchDocuments({ lang, page }))
    }, [lang, page])

    const onChange = (i) => dispatch(changeDocumentsPage(i))

    if (documentsLoadingStatus === 'ERR_NETWORK') return <Error />
    if (documentsLoadingStatus === 'loading') return <Loader />

    return (words && general) && (
        <Layout title={general.words[0].documents} href={general.words[0].documents} onChange={onChange} page={page} pagination={pagination}>
            <div className='documents'>
                <div className="documents__items title">
                    <div>
                        {words.number}
                    </div>
                    <div>
                        {words.documents_name}
                    </div>
                    <div>
                        {words.publication_date}
                    </div>
                    <div>
                        {words.acceptance_date}
                    </div>
                    <div>
                        {words.place_of_ac}
                    </div>
                    <div>
                        {words.views}
                    </div>
                </div>

                {documents.length > 0 ?
                    documents.map((item, i) => {
                        return (
                            <div key={i} className="documents__items">
                                <div>{item.number}</div>
                                <Link to={item.id} className='name'>
                                    {item.title}
                                </Link>
                                <div>
                                    {item.day}.
                                    {item.month}.
                                    {item.year}
                                </div>
                                <div>
                                    {item.acc_date_day}.
                                    {item.acc_date_month}.
                                    {item.acc_date_year}
                                </div>
                                <div>
                                    {item.place}
                                </div>
                                <div>
                                    {item.views}
                                </div>
                            </div>
                        )
                    }) : <NotFound>{lang === 'ky' ? 'Маалымат азырынча жок' : 'Пока данных нет'}</NotFound>
                }
            </div>
        </Layout>
    )
}

export default Documents