import React, { useEffect } from "react";
import { AiOutlineEye } from 'react-icons/ai'

import "./newsMore.scss";

import Layout from "components/layout";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchNewsMore } from "store/slices/newsSlice";
import SwiperGallery from "components/swiperGallery";
import Loader from "components/loader";
import Error from "components/error";
import NotFound from "components/notFound";

const NewsMore = () => {
    const { lang, general } = useSelector(state => state.lang)
    const { newsMore, newsLoadingStatus } = useSelector(state => state.news)
    const { param } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchNewsMore({ lang, param }))
    }, [lang, param])


    if (newsLoadingStatus === 'ERR_NETWORK') return <Error />
    if (newsLoadingStatus === 'loading') return <Loader />

    if (newsMore) {
        if (!newsMore.page[0].tema) {
            navigate('/news')
            return null
        }
    }


    return (newsMore && general) && (
        <>
            {
                newsMore.page.not_found ?
                    <NotFound>{newsMore.page.not_found}</NotFound>
                    :
                    <Layout title={newsMore.page[0].tema} href={<Link to='/news'>{general.words[0].news}</Link>}>
                        <div className="newsMore">
                            <div className="newsMore__date">
                                <div>
                                    {newsMore.page[0].day}.
                                    {newsMore.page[0].month}.
                                    {newsMore.page[0].year}
                                </div>
                                <div>
                                    <AiOutlineEye />
                                    {newsMore.page[0].views}
                                </div>
                            </div>

                            <SwiperGallery data={newsMore.photo} />

                            <div className="newsMore__text" dangerouslySetInnerHTML={{ __html: newsMore.page[0].text }}></div>
                        </div>
                    </Layout>
            }
        </>
    );
}

export default NewsMore