import React from 'react';

const YandexTracking = () => {
    React.useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://mc.yandex.ru/metrika/tag.js';
        script.async = true;
        script.onload = () => {
            if (window.ym) {
                window.ym(97220426, 'init', {
                    clickmap: true,
                    trackLinks: true,
                    accurateTrackBounce: true,
                });
            }
        };
        document.body.appendChild(script);

        const noscript = document.createElement('noscript');
        const img = document.createElement('img');
        img.src = 'https://mc.yandex.ru/watch/97220426';
        img.style.position = 'absolute';
        img.style.left = '-9999px';
        img.alt = '';
        noscript.appendChild(img);
        document.body.appendChild(noscript);

        return () => {
            const script = document.querySelector('script[src="https://mc.yandex.ru/metrika/tag.js"]');
            if (script) {
                script.remove();
            }
            const noscript = document.querySelector('noscript img[src="https://mc.yandex.ru/watch/97220426"]');
            if (noscript) {
                noscript.remove();
            }
        };
    }, []);

    return (
        <div>
            <a href="https://metrika.yandex.ru/stat/?id=97220426&amp;from=informer" target="_blank" rel="noopener noreferrer nofollow">
                <img
                    src="https://informer.yandex.ru/informer/97220426/3_1_FFFFFFFF_EFEFEFFF_0_pageviews"
                    style={{ width: '88px', height: '31px', border: '0' }}
                    alt="Яндекс.Метрика"
                    title="Яндекс.Метрика: данные за сегодня (просмотры, визиты и уникальные посетители)"
                    className="ym-advanced-informer"
                    data-cid="97220426"
                    data-lang="ru"
                />
            </a>
        </div>
    );
};

export default YandexTracking;