import { useSelector } from 'react-redux'
import './vacancies.scss'
import { Link } from 'react-router-dom'
import Grid from 'components/grid'

const Vacancies = () => {
    const { main } = useSelector(state => state.lang)

    return main.vacancy[0] && (
        <div className='vacancies'>
            <h2 className="vacancies__title">
                {main.vacancy[0].r_title}
            </h2>
            <div className="vacancies__items">
                <Grid>
                    {main.vacancy.map((item, i) => {
                        if (i === 0) return null
                        return (
                            <Link to={`/razdel/${main.vacancy[0].r_url}/r_url=${main.vacancy[0].r_url}&n_url=${item.n_url}`} key={i} className="vacancies__item">

                                <div className="vacancies__item-date">
                                    {item.day}.
                                    {item.month}.
                                    {item.year}
                                </div>
                                <h3 className="vacancies__item-title">
                                    {item.tema}
                                </h3>
                            </Link>
                        )
                    })}
                </Grid>
            </div>
        </div>
    )
}

export default Vacancies